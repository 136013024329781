import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  provideAppInitializer,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { WINDOW } from '@ng-web-apis/common';

import { CosFeatureFlagsToolbarControlsSet } from '@cosmos/feature-flags-toolbar';
import { PresentationTemplateService } from '@customer-portal/data-access-template';

/**
 * This is just for template POC.
 */
@Component({
  selector: 'customer-portal-feature-flags-template-select',
  template: `
    <div class="icon">
      <i class="fa fa-swatchbook"></i>
    </div>

    <div class="label">
      <select
        class="cos-input cos-input--small w-100"
        [ngModel]="templateService.getCurrentTemplate().Type"
        (ngModelChange)="changeFeatureFlagMode($event)"
      >
        @for (
          templateType of templateService.templateTypes;
          track templateType
        ) {
          <option [ngValue]="templateType">
            {{ templateType }}
          </option>
        }
      </select>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FormsModule],
})
export class FeatureFlagsTemplateSelectComponent {
  private readonly _window = inject(WINDOW);
  readonly templateService = inject(PresentationTemplateService);

  changeFeatureFlagMode(templateType: string): void {
    this.templateService.setCurrentTemplate(templateType);
    this._window.location.reload();
  }
}

export function withPresentationTemplateSelect() {
  return provideAppInitializer(() => {
    const controls = inject(CosFeatureFlagsToolbarControlsSet);
    controls.add(FeatureFlagsTemplateSelectComponent);
  });
}
