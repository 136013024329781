import { Directive, HostListener, input } from '@angular/core';

import type { ActionDelegateFactory } from '@cosmos/types-common';

import { injectActionExecutor } from '../injectors';

@Directive({
  selector: '[cosActionDelagateOnClick]',
})
export class CosActionDelegateOnClickDirective {
  private readonly _actionExecutor = injectActionExecutor();

  readonly action = input.required<ActionDelegateFactory | null | undefined>({
    alias: 'cosActionDelagateOnClick',
  });

  private _executing = false;

  @HostListener('click.silent')
  async handleClick() {
    const action = this.action();
    if (!action) return;
    /** Prevents a second call to the same service while It's being loaded */
    if (this._executing) return;

    this._executing = true;

    try {
      await this._actionExecutor(action);
    } finally {
      this._executing = false;
    }
  }
}
