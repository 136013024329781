import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { filter, forkJoin, noop, take } from 'rxjs';

import { ConfigService } from '@cosmos/config';
import { CustomerPortalAuthQueries } from '@customer-portal/auth/data-access-auth';
import {
  CustomerPortalProjectDetailsQueries,
  CustomerPortalProjectQueries,
} from '@customer-portal/projects/data-access/store';

export const enum PagesToTrackVisit {
  Proofs = 'proofs',
  Products = 'products',
  Order = 'order',
  Quote = 'quote',
  Invoice = 'invoice',
}

export interface NotificationPayload {
  projectId?: string;
  presentationId?: string;
  assetType?: string;
  assetId?: string;
}

@Injectable({ providedIn: 'root' })
export class CustomerPortalVisitNotificationsService {
  private readonly _apiUrl: string = inject(ConfigService).get('marsApiUrl');

  constructor(
    private readonly _http: HttpClient,
    private _router: Router,
    private _store: Store
  ) {
    _router.events
      .pipe(
        filter((event: any) => {
          return !!event.snapshot?.data?.visitedPage;
        }),
        take(1) // to send a notification once
      )
      .subscribe((event) => {
        const payload: NotificationPayload = {};
        payload.assetType = event.snapshot.data.visitedPage;

        switch (event.snapshot.data.visitedPage) {
          case PagesToTrackVisit.Quote:
            payload.assetId = event.snapshot.params.quoteId;
            break;
          case PagesToTrackVisit.Order:
            payload.assetId = event.snapshot.params.orderId;
            break;
          case PagesToTrackVisit.Invoice:
            payload.assetId = event.snapshot.params.invoiceId;
            break;
          // proofs and products do not have a specific asset id
        }

        const userIsDistributor = this._store.selectSnapshot(
          CustomerPortalAuthQueries.getUserIsDistributor
        );

        // prevent sending for the preview mode
        if (userIsDistributor) {
          return;
        }

        const projectId$ = this._store
          .select(CustomerPortalProjectQueries.getProjectId)
          .pipe(
            filter((projectId) => projectId !== null),
            take(1)
          );

        const presentationId$ = this._store
          .select(CustomerPortalProjectDetailsQueries.getPresentationId)
          .pipe(
            filter((presentationId) => presentationId !== null),
            take(1)
          );

        forkJoin({
          projectId: projectId$,
          presentationId: presentationId$,
        }).subscribe(({ projectId, presentationId }) => {
          this.sendNotification({
            ...payload,
            projectId: projectId!.toString(),
            presentationId: presentationId!.toString(),
          }).subscribe(noop);
        });
      });
  }

  sendNotification(payload: NotificationPayload) {
    const params = new HttpParams({
      fromObject: payload as { [param: string]: string },
    });
    return this._http.post<void>(
      `${this._apiUrl}/projects/${payload.projectId}/viewed`,
      {},
      { params }
    );
  }
}
