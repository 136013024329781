import { Injectable, Pipe, type PipeTransform } from '@angular/core';

import type { CapabilitiesEnum } from '@cosmos/types-common';

import { AuthFacade } from '../../services';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'hasCapability',
})
export class HasCapabilityPipe implements PipeTransform {
  constructor(private _authFacade: AuthFacade) {}

  transform(capability: CapabilitiesEnum): boolean {
    return !!this._authFacade.user?.hasCapability(capability);
  }
}
