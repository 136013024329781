import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'customer-portal-header-loader',
  template: `
    <div class="flex items-center justify-between w-full">
      <!-- left header -->
      <div class="flex items-center">
        <ngx-skeleton-loader
          class="flex"
          [theme]="{
            height: '50px',
            width: '50px',
            margin: '0 12px 0 0',
            'aspect-ratio': '1/1',
          }"
        />

        <div class="left-header-divider"></div>

        <div>
          <ngx-skeleton-loader
            [theme]="{ height: '14px', width: '280px', marginTop: '10px' }"
          />
        </div>
      </div>

      @if (showMenu()) {
        <!-- right header -->
        <div class="flex justify-between gap-5" id="menu-icons">
          <div class="flex flex-col pt-2.5">
            <ngx-skeleton-loader
              [theme]="{
                height: '1rem',
                width: '60px',
                'margin-bottom': '0',
              }"
            />
          </div>
          <div class="flex flex-col pt-2.5">
            <ngx-skeleton-loader
              [theme]="{
                height: '1rem',
                width: '60px',
                'margin-bottom': '0',
              }"
            />
          </div>
        </div>
      }
    </div>
  `,
  styles: [
    `
      @use '@cosmos/scss/settings' as s;

      .left-header-divider {
        @apply mr-3 w-px;

        height: 3.125rem;
        background: theme('colors.base.300');
      }

      @include s.narrower-than(theme('screens.lg')) {
        #menu-icons {
          display: none;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgxSkeletonLoaderModule],
})
export class CustomerPortalHeaderLoaderComponent {
  readonly showMenu = input(true);
}
