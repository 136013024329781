import { Injectable } from '@angular/core';
import type { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, EMPTY } from 'rxjs';

import { getParamsObject } from '@cosmos/core';
import {
  CustomerPortalDistributorActions,
  CustomerPortalPresentationActions,
  CustomerPortalPresentationQueries,
} from '@customer-portal/presentations/data-access-presentations';
import {
  CustomerPortalProjectDetailsActions,
  CustomerPortalProjectDetailsQueries,
} from '@customer-portal/projects/data-access/store';

@Injectable({ providedIn: 'root' })
export class CustomerPortalProjectDetailsResolver implements Resolve<void> {
  constructor(private readonly _store: Store) {}

  resolve(route: ActivatedRouteSnapshot): void {
    const { projectId } = getParamsObject(route.paramMap, {
      required: ['projectId'],
    })!;

    // Note: do not return the result of this observable since it shouldn't
    // block the UI from loading; this should act as fire & forget; hereby,
    // we're able to show skeleton loaders.
    this._store
      .dispatch(
        new CustomerPortalProjectDetailsActions.GetProjectDetails(projectId)
      )
      .pipe(catchError(() => EMPTY))
      .subscribe(() => {
        // We first need to get the project details since it has the `PresentationId`.
        const presentationId = this._store.selectSnapshot(
          CustomerPortalProjectDetailsQueries.getPresentationId
        );
        const presentation = this._store.selectSnapshot(
          CustomerPortalPresentationQueries.getPresentation
        );
        const actions: Array<object> = [
          new CustomerPortalDistributorActions.GetDistributor(presentationId),
          new CustomerPortalDistributorActions.GetDesign(presentationId),
        ];
        // load presentation only if it's not loaded yet
        if (presentation?.Id !== presentationId) {
          actions.push(
            new CustomerPortalPresentationActions.GetPresentation({
              presentationId,
              shouldLoadQuote: true,
              settings: route.queryParamMap.get('settings'),
            })
          );
        }
        this._store.dispatch(actions);
      });
  }
}
