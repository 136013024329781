@if (options) {
  <div class="dev-tools" [class.active]="menuToggle()">
    <div class="toggle-nav" (click)="toggleMenuClicked()">
      <div class="toggle-nav-button">
        <div class="title">Dev Tools</div>
      </div>
    </div>
    <div class="toggle-nav-menu">
      @for (control of toolbarControls; track control) {
        <div class="toggle-nav-menu-item">
          <ng-template [ngComponentOutlet]="control" />
        </div>
      }
      <!-- <div class="menu-item">
      <div class="icon">
        <i class="fas fa-dollar-sign"></i>
      </div>
      <div class="label">
        <div class="gap-2 flex">
          <div>
            <select [(ngModel)]="currencyPreference.DefaultCurrencyCode">
              <option value="USD">USD</option>
              <option value="CAD">CAD</option>
            </select>
          </div>
          <div>
            <label>USD to CAD: </label>
            <input
              type="number"
              min="1"
              max="99"
              [(ngModel)]="currencyPreference.Conversions[0].ConversionRate"
              />
          </div>
          <div>
            <label>CAD to USD: </label>
            <input
              type="number"
              min="1"
              max="99"
              [(ngModel)]="currencyPreference.Conversions[1].ConversionRate"
              />
          </div>
        </div>
      </div>
    </div> -->
    </div>
  </div>
}
