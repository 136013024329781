<div class="customer-portal-header-topbar">
  <div class="customer-portal-header-topbar--container flex justify-between">
    @if (state.isReady) {
      <div class="customer-portal-header-topbar--info">
        @if (state.project!.Customer.LogoImageUrl) {
          <div class="customer-portal-header-client-logo">
            <img
              [attr.src]="state.project!.Customer.LogoImageUrl"
              [alt]="state.project!.Name"
            />
          </div>
        }
        <div class="customer-portal-header-event-name">
          <h2>{{ state.project!.Name }}</h2>
        </div>
      </div>
      @if (showMenu()) {
        <cos-global-menu
          class="self-center"
          [navItemsDesktop]="navItemsDesktop()"
          [navItemsMobile]="navItemsMobile()"
        />
      }
    } @else {
      <customer-portal-header-loader class="w-full" [showMenu]="showMenu()" />
    }
  </div>
</div>
@if (state.presentationExpired && state.isReady && state.isProductsPage) {
  <div class="expiration-banner text-header-base flex justify-center">
    This Presentation has expired. Please reach out to
    {{ state.distributor?.Name }} for the most recent product information.
  </div>
}
