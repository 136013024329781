import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  signal,
  ViewEncapsulation,
  type OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, switchMap } from 'rxjs';

import { CosButtonModule } from '@cosmos/components/button';
import { CosCardModule } from '@cosmos/components/card';
import { CosGlobalMenuComponent } from '@cosmos/components/global-header';
import { useLocalState } from '@cosmos/state';
import type { MenuItem, NavigationItem } from '@cosmos/types-layout';
import { injectDestroyRef } from '@cosmos/util-common';
import {
  CosmosTranslocoService,
  CosmosUtilTranslationsModule,
  LanguageScope,
} from '@cosmos/util-translations';

import { CustomerPortalHeaderLoaderComponent } from './components/header-loader';
import { HeaderLocalState } from './header.local-state';
import { assembleDesktopItems, assembleMobileItems } from './menu-items';

@Component({
  selector: 'customer-portal-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'customer-portal-header',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HeaderLocalState],
  imports: [
    CosButtonModule,
    CosCardModule,
    CosGlobalMenuComponent,
    CosmosUtilTranslationsModule,
    CustomerPortalHeaderLoaderComponent,
  ],
})
export class CustomerPortalHeaderComponent implements OnInit {
  readonly navItemsDesktop = signal<NavigationItem[]>([]);
  readonly navItemsMobile = signal<MenuItem[]>([]);

  readonly showDetails = signal(false);

  readonly state = useLocalState(HeaderLocalState, this);

  private readonly _destroyRef = injectDestroyRef();

  constructor(
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _translocoService: CosmosTranslocoService
  ) {}

  @HostBinding('style.border-color')
  get classList() {
    return this.state.project?.Customer.PrimaryBrandColor;
  }

  ngOnInit(): void {
    this.state
      .asObservable()
      .pipe(
        filter((state) => state.isReady),
        switchMap(() =>
          this._translocoService.ensureScopesAreLoaded$([
            LanguageScope.CustomerPortalCommon,
          ])
        ),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe(() => {
        this.updateMenuItems();
      });
  }

  updateMenuItems() {
    this.navItemsDesktop.set(assembleDesktopItems(this.state));
    this.navItemsMobile.set(assembleMobileItems(this.state));
    this._cdRef.detectChanges();
  }
}
