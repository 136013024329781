import { InjectionToken } from '@angular/core';

export interface FeatureFlagsQueryParamCheck {
  queryFlag: string;
}

export const FEATURE_FLAGS_FROM_QUERY_DATA =
  new InjectionToken<FeatureFlagsQueryParamCheck>(
    ngDevMode ? 'FEATURE_FLAGS_FROM_QUERY_DATA' : ''
  );
