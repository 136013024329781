import { inject, Pipe, type PipeTransform } from '@angular/core';

import type { FeatureFlags } from '@cosmos/types-feature-flags';

import { FeatureFlagsService } from '../providers';

@Pipe({
  name: 'featureFlagsFilter',
})
export class FeatureFlagsFilterPipe<T extends FeatureFlags>
  implements PipeTransform
{
  private readonly _isFlagEnabled: (flagIdentifier: string) => boolean;

  constructor() {
    const featureFlagsService = inject(FeatureFlagsService);
    this._isFlagEnabled =
      featureFlagsService.isEnabled.bind(featureFlagsService);
  }

  transform(items: T[]): T[] {
    return this.filterByFeatureFlags(items);
  }

  protected filterByFeatureFlags(items: T[]) {
    return (items || []).filter((item) => {
      const flags = new Array<string>().concat(
        item.featureFlags?.matches || []
      );
      return flags.every(this._isFlagEnabled);
    });
  }
}
