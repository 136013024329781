/**
 * This code is almost a copy from https://github.com/angular/components/tree/16.2.10/src/material/bottom-sheet
 */

import type { AnimationEvent } from '@angular/animations';
// eslint-disable-next-line no-restricted-syntax
import { CdkDialogContainer, DialogModule } from '@angular/cdk/dialog';
import { PortalModule } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  ViewEncapsulation,
  type OnDestroy,
} from '@angular/core';

import { isDesktop, isMobile, isTablet } from '@cosmos/util-screen-size';

import { cosBottomSheetAnimations } from './bottom-sheet-animations';

/**
 * Internal component that wraps user-provided bottom sheet content.
 * @docs-private
 */
@Component({
  selector: 'cos-bottom-sheet-container',
  template: '<ng-template cdkPortalOutlet />',
  styleUrls: ['./bottom-sheet-container.scss'],
  // In Ivy embedded views will be change detected from their declaration place, rather than where
  // they were stamped out. This means that we can't have the bottom sheet container be OnPush,
  // because it might cause the sheets that were opened from a template not to be out of date.
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  animations: [cosBottomSheetAnimations.bottomSheetState],
  host: {
    class: 'cos-bottom-sheet-container',
    tabindex: '-1',
    '[attr.role]': '_config.role',
    '[attr.aria-modal]': '_config.ariaModal',
    '[attr.aria-label]': '_config.ariaLabel',
    '[@state]': '_animationState',
    '(@state.start)': '_onAnimationStart($event)',
    '(@state.done)': '_onAnimationDone($event)',
    '[class.cos-bottom-sheet-container-mobile]': 'isMobile()',
    '[class.cos-bottom-sheet-container-tablet]': 'isTablet()',
    '[class.cos-bottom-sheet-container-desktop]': 'isDesktop()',
  },
  imports: [PortalModule, DialogModule],
})
export class CosBottomSheetContainerComponent
  extends CdkDialogContainer
  implements OnDestroy
{
  /** The state of the bottom sheet animations. */
  _animationState: 'void' | 'visible' | 'hidden' = 'void';

  /** Emits whenever the state of the animation changes. */
  _animationStateChanged = new EventEmitter<AnimationEvent>();

  /** Whether the component has been destroyed. */
  private _destroyed = false;

  readonly isDesktop = isDesktop();
  readonly isTablet = isTablet();
  readonly isMobile = isMobile();

  /** Begin animation of bottom sheet entrance into view. */
  enter(): void {
    if (!this._destroyed) {
      this._animationState = 'visible';
      this._changeDetectorRef.detectChanges();
    }
  }

  /** Begin animation of the bottom sheet exiting from view. */
  exit(): void {
    if (!this._destroyed) {
      this._animationState = 'hidden';
      this._changeDetectorRef.markForCheck();
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this._destroyed = true;
  }

  _onAnimationDone(event: AnimationEvent) {
    if (event.toState === 'visible') {
      this._trapFocus();
    }

    this._animationStateChanged.emit(event);
  }

  _onAnimationStart(event: AnimationEvent) {
    this._animationStateChanged.emit(event);
  }

  protected override _captureInitialFocus(): void {}
}
