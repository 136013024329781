import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  type OnInit,
} from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { Store } from '@ngxs/store';

import { IframeDataBus, initStoreDispatcher } from '@cosmos/util-data-bus';
import { CustomerPortalVisitNotificationsService } from '@customer-portal/common/data-access-visit-notification';
import { CustomerPortalLayoutComponent } from '@customer-portal/common/ui-layout';
import { LookupsActions } from '@esp/lookup/data-access-lookup';
import { SwUpdateService } from '@esp/notifications/feature-notifications-refresh';

@Component({
  selector: 'customer-portal-app-root',
  templateUrl: './app-root.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CustomerPortalLayoutComponent],
})
export class AppRootComponent implements OnInit {
  private readonly _document = inject(DOCUMENT);
  private readonly _store = inject(Store);
  private readonly _swUpdate = inject(SwUpdateService);

  constructor() {
    //needed to visit tracking
    inject(CustomerPortalVisitNotificationsService);

    this._swUpdate.waitForUpdate();

    const dataBus = new IframeDataBus({ currentWindow: inject(WINDOW) });
    initStoreDispatcher(dataBus);
  }

  ngOnInit() {
    // TODO(portal-url-migration): remove once we don't support legacy presentations URL.
    if (this._document.location.pathname.startsWith('/projects')) {
      // Should not be loaded if the user navigates to legacy `/presentations` URL,
      // since it's an extra request.
      this._store.dispatch([
        new LookupsActions.Load('Carriers'),
        new LookupsActions.Load('Countries'),
      ]);
    }
  }
}
