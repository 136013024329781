import { CommonModule, DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ChangeDetectionStrategy,
  Component,
  createComponent,
  EmbeddedViewRef,
  inject,
  Injectable,
  signal,
  Type,
  type OnInit,
} from '@angular/core';

import {
  ExplicitFeatureFlagsService,
  FEATURE_FLAGS_OPTIONS,
  type FeatureFlagsOptions,
} from '@cosmos/feature-flags';
import { CurrencyCode, type CurrencyPreferences } from '@cosmos/types-common';

@Injectable({ providedIn: 'root' })
export class CosFeatureFlagsToolbarControlsSet extends Set<Type<unknown>> {}

@Component({
  selector: 'cos-feature-flags-toolbar',
  templateUrl: 'feature-flags-toolbar.component.html',
  styleUrls: ['feature-flags-toolbar.component.scss'],
  host: {
    class: 'cos-feature-flags-toolbar',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class CosFeatureFlagsToolbarComponent implements OnInit {
  private readonly _featureFlagService = inject(ExplicitFeatureFlagsService);
  readonly options = inject<FeatureFlagsOptions>(FEATURE_FLAGS_OPTIONS);
  readonly toolbarControls = inject(CosFeatureFlagsToolbarControlsSet);

  featureFlag: string = this._featureFlagService.selectedFeatureFlagName;

  menuToggle = signal(false);

  currencyPreference: CurrencyPreferences = {
    DefaultCurrencyCode: CurrencyCode.CAD,
    Conversions: [
      {
        Id: 1,
        SourceType: CurrencyCode.USD,
        TargetType: CurrencyCode.CAD,
        ConversionRate: 1.5,
      },
      {
        Id: 2,
        SourceType: CurrencyCode.CAD,
        TargetType: CurrencyCode.USD,
        ConversionRate: 0.75,
      },
    ],
  };

  private readonly _document = inject(DOCUMENT);

  ngOnInit(): void {
    this.setFeatureFlagMode();
  }

  setFeatureFlagMode(): void {
    const option = this.options?.[this.featureFlag];

    if (option) {
      option.color
        ? this._document.documentElement.style.setProperty(
            '--environment-color',
            option.color
          )
        : this._document.documentElement.style.removeProperty(
            '--environment-color'
          );

      this._featureFlagService.setFlags(option.flags);
    }
  }
  toggleMenuClicked() {
    this.menuToggle.update((v) => !v);
  }
}

export function renderFeatureFlagsToolbar(appRef: ApplicationRef): void {
  const { hostView, changeDetectorRef } = createComponent(
    CosFeatureFlagsToolbarComponent,
    {
      environmentInjector: appRef.injector,
    }
  );

  appRef.attachView(hostView);
  // eslint-disable-next-line no-restricted-globals
  document.body.appendChild(
    (hostView as EmbeddedViewRef<CosFeatureFlagsToolbarComponent>).rootNodes[0]
  );

  changeDetectorRef.detectChanges();
}
