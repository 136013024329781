import { Pipe, type PipeTransform } from '@angular/core';

import type { NavigationItem } from '@cosmos/types-layout';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { HasCapabilityPipe } from '@esp/auth/data-access-auth';

@Pipe({
  name: 'capabilityFilter',
})
export class CapabilityFilterPipe<T extends NavigationItem>
  implements PipeTransform
{
  constructor(private readonly _hasCapabilityPipe: HasCapabilityPipe) {}

  transform(items: T[]): T[] {
    return items
      .map((item) => this._checkCapabilitiesRecursively(item))
      .filter((item) => !item.hidden);
  }

  private _checkCapabilitiesRecursively(item: T): T {
    const children = item.children?.map((item) =>
      // `item as any` is used because `children` is not a generic
      // type that extends `INavigationItem`, but rather an exact
      // `INavigationItem[]` type.
      this._checkCapabilitiesRecursively(item as any)
    );

    if (
      !item.capabilities ||
      item.capabilities.every((capability) =>
        this._hasCapabilityPipe.transform(capability)
      )
    ) {
      return { ...item, children };
    } else {
      return { ...item, children, hidden: true };
    }
  }
}
