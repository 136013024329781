import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
  type CanActivateFn,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';

import { getParamsObject } from '@cosmos/core';
import {
  CustomerPortalAuthActions,
  CustomerPortalAuthQueries,
} from '@customer-portal/auth/data-access-auth';

/**
 * This guard is used to handle the authentication on customer portal.
 */
export const customerPortalAuthGuard: CanActivateFn = async function (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Promise<UrlTree | boolean> {
  const store = inject(Store);
  const router = inject(Router);
  const params = getParams(route)!;

  const session = store.selectSnapshot(CustomerPortalAuthQueries.getSession);
  // If `session` exists then the user has been already authenticated and we should just
  // allow bypassing this guard.
  // The `!params.token && !params.accessCode` condition is used to ensure the page is not previewed by distributor.
  // Suppose the distributor was logged in with token A, but this token has been expired, and
  // now the distributor has token B. The `getSession` will still return token A, because it was
  // persisted into the session storage.
  if (session && !params.token && !params.accessCode) {
    return true;
  }

  // login by explicit token
  if (params.token) {
    await firstValueFrom(
      store.dispatch(
        new CustomerPortalAuthActions.LoginSuccess({
          access_token: params.token,
        })
      )
    );
    return getUrlTreeWithoutTokens(router, state);
  }

  // login by accessCode
  if (params.accessCode && (params.projectId || params.presentationId)) {
    await firstValueFrom(
      store.dispatch(
        new CustomerPortalAuthActions.LoginWithAccessCode({
          accessCode: params.accessCode,
          projectId: params.projectId,
          presentationId: params.presentationId,
        })
      )
    );
    return getUrlTreeWithoutTokens(router, state);
  }

  // If the user is not authenticated, we should redirect him away
  return router.createUrlTree(['not-found']);
};

const getParams = (route: ActivatedRouteSnapshot) => {
  const params = getParamsObject(route.paramMap, {
    optional: ['projectId', 'presentationId'],
  });

  const queryParams = getParamsObject(route.queryParamMap, {
    optional: ['accessCode', 'token', 'postPayment'],
  });

  return (
    params &&
    queryParams && {
      projectId: params.projectId && parseInt(params.projectId, 10),
      presentationId:
        params.presentationId && parseInt(params.presentationId, 10),
      accessCode: queryParams.accessCode,
      token: queryParams.postPayment ? undefined : queryParams.token,
    }
  );
};

const getUrlTreeWithoutTokens = (
  router: Router,
  state: RouterStateSnapshot
): UrlTree => {
  const urlTree = router.parseUrl(state.url);
  delete urlTree.queryParams['accessCode'];
  delete urlTree.queryParams['token'];
  return urlTree;
};
