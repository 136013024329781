import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, State, Store, type StateContext } from '@ngxs/store';
import { orderBy } from 'lodash-es';
import { filter, forkJoin, map, mergeMap, take, tap } from 'rxjs';

import {
  createNavigateToAssetNotFoundPageAction,
  CustomerPortalAssetName,
} from '@customer-portal/common/ui-asset-not-found';
import { CustomerPortalOrdersApiService } from '@customer-portal/orders/data-access/api';
import {
  CustomerPortalProjectDetailsActions,
  CustomerPortalProjectQueries,
} from '@customer-portal/projects/data-access/store';
import type { SearchCriteria } from '@esp/common/types';
import type { OrderSearch } from '@esp/orders/types';

import { CustomerPortalOrdersSearchActions } from '../actions';

export interface CustomerPortalOrdersSearchStateModel {
  total: number;
  criteria: SearchCriteria | null;
  orders: OrderSearch[] | null;
}

type ThisStateContext = StateContext<CustomerPortalOrdersSearchStateModel>;

const MAX_SUPPORTED_PROJECTS_SEARCH_RESULT_COUNT = 1000;

@State<CustomerPortalOrdersSearchStateModel>({
  name: 'ordersSearch',
  defaults: {
    total: 0,
    criteria: {
      from: 1,
      size: 50,
    },
    orders: null,
  },
})
@Injectable()
export class CustomerPortalOrdersSearchState {
  constructor(
    private readonly _store: Store,
    private readonly _ordersApiService: CustomerPortalOrdersApiService
  ) {}

  @Action(CustomerPortalOrdersSearchActions.Search, { cancelUncompleted: true })
  search(
    ctx: ThisStateContext,
    action: CustomerPortalOrdersSearchActions.Search
  ) {
    ctx.patchState({ orders: null });

    // We should wait until the project is loaded since the `Search` might be
    // dispatched meanwhile the project is still loading.
    const projectId$ = this._store
      .select(CustomerPortalProjectQueries.getProjectId)
      .pipe(
        filter((projectId): projectId is number => projectId != null),
        take(1)
      );

    return projectId$.pipe(
      mergeMap((projectId) =>
        forkJoin([
          this._ordersApiService
            .getCustomerVisibleOrders(projectId, action.criteria)
            .pipe(map((response) => ({ projectId, response }))),
          this._ordersApiService
            .getCustomerVisibleSampleRequests(projectId, action.criteria)
            .pipe(map((response) => ({ projectId, response }))),
        ])
      ),
      tap(([ordersResponse, sampleRequestsResponse]) => {
        let orders = (ordersResponse.response?.Results || []).concat(
          sampleRequestsResponse.response?.Results || []
        );
        orders = orderBy(
          orders,
          [(order) => new Date(order.CreateDate)],
          ['desc']
        );
        const totalResults =
          (ordersResponse.response.ResultsTotal ?? 0) +
          (sampleRequestsResponse.response.ResultsTotal ?? 0);

        // If here is only 1 order available then we should load the individual order page.
        // We have to check for `ResultsTotal` and not for `Results.length` because the user might
        // be navigating between pages when paginating.
        if (totalResults === 0) {
          ctx.dispatch([
            // recalculate the header values
            new CustomerPortalProjectDetailsActions.GetProjectDetails(
              ordersResponse.projectId
            ),
            createNavigateToAssetNotFoundPageAction(
              ordersResponse.projectId,
              CustomerPortalAssetName.Order
            ),
          ]);
        } else if (totalResults === 1) {
          ctx.dispatch(
            new Navigate([
              `/projects/${ordersResponse.projectId}/orders/${orders[0].Id}`,
            ])
          );
        } else {
          ctx.patchState({
            orders,
            criteria: action.criteria,
            total: Math.min(
              totalResults ?? 0,
              MAX_SUPPORTED_PROJECTS_SEARCH_RESULT_COUNT
            ),
          });
        }
      })
    );
  }

  @Action(CustomerPortalOrdersSearchActions.Prune)
  prune(ctx: ThisStateContext) {
    ctx.patchState({ orders: null });
  }
}
