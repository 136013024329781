/**
 * This code is almost a copy from https://github.com/angular/components/tree/16.2.10/src/material/bottom-sheet
 */

import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger,
  type AnimationTriggerMetadata,
} from '@angular/animations';

import { AnimationCurves, AnimationDurations } from '@cosmos/animations';

/** Animations used by the Material bottom sheet. */
export const cosBottomSheetAnimations: {
  readonly bottomSheetState: AnimationTriggerMetadata;
} = {
  /** Animation that shows and hides a bottom sheet. */
  bottomSheetState: trigger('state', [
    state('void, hidden', style({ transform: 'translateY(100%)' })),
    state('visible', style({ transform: 'translateY(0%)' })),
    transition(
      'visible => void, visible => hidden',
      group([
        animate(
          `${AnimationDurations.Complex} ${AnimationCurves.AccelerationCurve}`
        ),
        query('@*', animateChild(), { optional: true }),
      ])
    ),
    transition(
      'void => visible',
      group([
        animate(
          `${AnimationDurations.Exiting} ${AnimationCurves.DecelerationCurve}`
        ),
        query('@*', animateChild(), { optional: true }),
      ])
    ),
  ]),
};
