import type { ActivatedRouteSnapshot, Router } from '@angular/router';

interface RouteParams {
  [param: string]: string;
}

export function collectRouteParams(router: Router): RouteParams {
  let params = {};
  const stack: ActivatedRouteSnapshot[] = [router.routerState.snapshot.root];
  while (stack.length > 0) {
    const route = stack.pop()!;
    params = { ...params, ...route.params };
    stack.push(...route.children);
  }
  return params;
}

export function collectRouteQueryParams(router: Router): RouteParams {
  let params = {};
  const stack: ActivatedRouteSnapshot[] = [router.routerState.snapshot.root];
  while (stack.length > 0) {
    const route = stack.pop()!;
    params = { ...params, ...route.queryParams };
    stack.push(...route.children);
  }
  return params;
}
