import { Injectable } from '@angular/core';
import { Action, State, Store, type StateContext } from '@ngxs/store';
import { isNumber } from 'lodash-es';
import { catchError, EMPTY, filter, mergeMap, take, tap } from 'rxjs';

import { SetTitle } from '@cosmos/meta';
import { optimisticUpdate } from '@cosmos/state';
import { ToastActions } from '@cosmos/types-notification-and-toast';
import { LanguageScope } from '@cosmos/util-translations';
import {
  createNavigateToAssetNotFoundPageAction,
  CustomerPortalAssetName,
} from '@customer-portal/common/ui-asset-not-found';
import { CustomerPortalProjectQueries } from '@customer-portal/projects/data-access/store';
import { CustomerPortalQuotesApiService } from '@customer-portal/quotes/data-access/api';
import type { Order, OrderMessage, ProductLineItem } from '@esp/orders/types';

import { CustomerPortalQuoteActions } from '../actions';

import { TOAST_MESSAGES } from './toast-messages';

export interface CustomerPortalQuoteStateModel {
  quote: Order | null;
}

type ThisStateContext = StateContext<CustomerPortalQuoteStateModel>;

@State<CustomerPortalQuoteStateModel>({
  name: 'quote',
  defaults: {
    quote: null,
  },
})
@Injectable()
export class CustomerPortalQuoteState {
  private readonly _projectId$ = this._store
    .select(CustomerPortalProjectQueries.getProjectId)
    .pipe(filter(isNumber), take(1));

  constructor(
    private readonly _store: Store,
    private readonly _quotesApiService: CustomerPortalQuotesApiService
  ) {}

  @Action(CustomerPortalQuoteActions.GetQuote)
  getQuote(ctx: ThisStateContext, action: CustomerPortalQuoteActions.GetQuote) {
    return this._projectId$.pipe(
      mergeMap((projectId) =>
        this._quotesApiService.getQuote(projectId, action.quoteId).pipe(
          catchError((error) => {
            if (error.status === 404) {
              ctx.dispatch(
                createNavigateToAssetNotFoundPageAction(
                  projectId,
                  CustomerPortalAssetName.Quote
                )
              );
            }

            return EMPTY;
          })
        )
      ),
      tap((quote) => {
        ctx.patchState({ quote });
        ctx.dispatch([
          new SetTitle(
            `${LanguageScope.CustomerPortalCommon}.route-titles.presentation-quote-details`,
            { quoteNumber: quote.Number }
          ),
        ]);
      })
    );
  }

  @Action(CustomerPortalQuoteActions.SubmitChangeRequest)
  submitChangeRequest(
    ctx: ThisStateContext,
    action: CustomerPortalQuoteActions.SubmitChangeRequest
  ) {
    const { quote } = ctx.getState();
    const newLineItems = updateOptimisticallyLineItemMessages(
      quote as Order<ProductLineItem>,
      action.messages
    );

    const newQuote = <Order>{
      ...quote,
      Messages: action.messages,
      LineItems: newLineItems,
    };

    return this._quotesApiService
      .submitChangeRequest(action.projectId, action.quoteId, action.messages)
      .pipe(
        optimisticUpdate(newQuote, {
          getRollbackValue: () => quote,
          setValue: (quote) => {
            return ctx.patchState({ quote });
          },
        }),
        tap(() =>
          ctx.dispatch([
            new ToastActions.Show(TOAST_MESSAGES.CHANGE_REQUEST_CREATED()),
            new CustomerPortalQuoteActions.GetQuote(action.quoteId),
          ])
        ),
        catchError(() => {
          ctx.dispatch(
            new ToastActions.Show(TOAST_MESSAGES.CHANGE_REQUEST_CREATE_FAIL())
          );
          return EMPTY;
        })
      );
  }

  @Action(CustomerPortalQuoteActions.ApproveQuote)
  approveQuote(
    ctx: ThisStateContext,
    action: CustomerPortalQuoteActions.ApproveQuote
  ) {
    return this._quotesApiService
      .approveQuote(action.projectId, action.quoteId)
      .pipe(
        tap(() =>
          ctx.dispatch([
            new ToastActions.Show(TOAST_MESSAGES.QUOTE_APPROVED()),
            new CustomerPortalQuoteActions.GetQuote(action.quoteId),
          ])
        ),
        catchError(() => {
          ctx.dispatch(
            new ToastActions.Show(TOAST_MESSAGES.QUOTE_APPROVE_FAIL())
          );
          return EMPTY;
        })
      );
  }
}

const updateOptimisticallyLineItemMessages = (
  quote: Order<ProductLineItem>,
  messages: OrderMessage[]
) => {
  return (quote?.LineItems as ProductLineItem[]).map(
    (lineItem: ProductLineItem) => {
      const lineItemsMessages = messages.filter(
        (message) => message.LineItemId === lineItem.Id
      );

      if (lineItemsMessages && lineItemsMessages.length > 0) {
        return {
          ...lineItem,
          Messages: [
            ...(lineItem.Messages ? lineItem.Messages : []),
            ...lineItemsMessages,
          ],
        };
      }

      return lineItem;
    }
  );
};
