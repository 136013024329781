import { provideStates } from '@ngxs/store';

import type { TypedRoute } from '@cosmos/router';
import { LanguageScope } from '@cosmos/util-translations';
import {
  customerPortalAuthGuard,
  customerPortalLegacyUrlRedirectHandler,
} from '@customer-portal/auth/data-access-customer-portal-access-guard';
import { PagesToTrackVisit } from '@customer-portal/common/data-access-visit-notification';
import { CustomerPortalOrdersSearchState } from '@customer-portal/orders/data-access/store';
import {
  CustomerPortalQuotesSearchState,
  CustomerPortalQuoteState,
} from '@customer-portal/quotes/data-access/store';

import { CustomerPortalProjectsHostComponent } from './projects-host/projects-host.component';
import { CustomerPortalProjectDetailsResolver } from './resolvers';

function getTitle(key: string) {
  return `${LanguageScope.CustomerPortalCommon}.route-titles.${key}`;
}

export const routes: TypedRoute<{ visitedPage?: PagesToTrackVisit }>[] = [
  {
    path: 'projects/:projectId',
    canActivate: [customerPortalAuthGuard],
    resolve: [CustomerPortalProjectDetailsResolver],
    component: CustomerPortalProjectsHostComponent,
    children: [
      {
        path: 'presentations',
        loadChildren: async () =>
          (await import('@customer-portal/presentations/feature-presentations'))
            .routes,
        data: {
          visitedPage: PagesToTrackVisit.Products,
          preload: true,
        },
      },
      {
        path: 'proofs',
        loadChildren: async () =>
          (
            await import(
              /* webpackChunkName: 'feature-proofs' */ '@customer-portal/proofs/feature-proofs'
            )
          ).CustomerPortalProofsFeatureProofsModule,
        data: {
          visitedPage: PagesToTrackVisit.Proofs,
          meta: {
            title: getTitle('proofs'),
          },
        },
      },
      {
        path: 'orders',
        children: [
          {
            path: '',
            loadComponent: async () =>
              (await import('@customer-portal/orders/feature-orders-list'))
                .CustomerPortalOrdersListPage,
            providers: [provideStates([CustomerPortalOrdersSearchState])],
            data: {
              meta: {
                title: getTitle('orders'),
              },
            },
          },
          {
            path: ':orderId',
            loadComponent: async () =>
              (
                await import(
                  /* webpackChunkName: 'feature-order-detail' */ '@customer-portal/orders/feature-order-details'
                )
              ).CustomerPortalOrderDetailsPage,
            data: {
              visitedPage: PagesToTrackVisit.Order,
            },
          },
        ],
      },
      {
        path: 'quotes',
        children: [
          {
            path: '',
            loadComponent: async () =>
              (await import('@customer-portal/quotes/feature-quotes-list'))
                .CustomerPortalQuotesListPage,
            providers: [provideStates([CustomerPortalQuotesSearchState])],
            data: {
              meta: {
                title: getTitle('quotes'),
              },
            },
          },
          {
            path: ':quoteId',
            loadComponent: async () =>
              (await import('@customer-portal/quotes/feature-quote-details'))
                .CustomerPortalQuoteDetailsPage,
            providers: [provideStates([CustomerPortalQuoteState])],
            data: {
              visitedPage: PagesToTrackVisit.Quote,
            },
          },
        ],
      },
      {
        path: 'invoices',
        loadChildren: () =>
          import('@customer-portal/invoices/feature-shell-web'),
        data: {
          meta: {
            title: getTitle('invoices'),
          },
        },
      },
      {
        path: 'asset-not-found',
        loadComponent: async () =>
          (
            await import(
              /* webpackChunkName: 'ui-asset-not-found' */ '@customer-portal/common/ui-asset-not-found'
            )
          ).CustomerPortalAssetNotFoundComponent,
      },
    ],
  },
  {
    path: 'collections',
    loadChildren: async () =>
      (
        await import(
          /* webpackChunkName: 'feature-collection' */ '@customer-portal/collections/feature-collection'
        )
      ).customerPortalCollectionRoutes,
    data: {
      meta: {
        title: getTitle('collections'),
      },
    },
  },
  {
    path: 'products',
    loadChildren: async () =>
      (await import('@customer-portal/collections/feature-product-details'))
        .customerPortalProductRoutes,
    data: {
      meta: {
        title: getTitle('products'),
      },
    },
  },

  // (dt): Is it a really legacy URL? Looks like it's the main flow to share a presentation.
  // This is the legacy URL handler that will redirect to /projects/:projectId/presentations/....
  {
    path: 'presentations/:presentationId',
    canActivate: [customerPortalLegacyUrlRedirectHandler],
    children: [
      {
        path: '**',
        children: [],
      },
    ],
  },

  {
    path: '**',
    loadComponent: async () =>
      (await import('@cosmos/ui-not-found')).CosmosNotFoundPage,
    data: {
      meta: {
        robots: 'noindex, nofollow',
      },
    },
  },
];
