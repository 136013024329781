import { Pipe, type PipeTransform } from '@angular/core';

import type { NavigationItem } from '@cosmos/types-layout';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthFacade } from '@esp/auth/data-access-auth';

@Pipe({
  name: 'roleFilter',
})
export class RoleFilterPipe<T extends NavigationItem> implements PipeTransform {
  constructor(private readonly _authFacade: AuthFacade) {}

  transform(items: T[]): T[] {
    return items.map((item) => this._checkRolesRecursively(item));
  }

  private _checkRolesRecursively(item: T): T {
    const children = item.children?.map((item) =>
      // `item as any` is used because `children` is not a generic
      // type that extends `INavigationItem`, but rather an exact
      // `INavigationItem[]` type.
      this._checkRolesRecursively(item as any)
    );

    const hasRoles =
      !item.roles ||
      (item.rolesFilter === 'some'
        ? item.roles.some((role) => this._authFacade.user?.hasRole(role))
        : item.roles.every((role) => this._authFacade.user?.hasRole(role)));

    if (hasRoles) {
      return { ...item, children };
    } else {
      return { ...item, children, hidden: true };
    }
  }
}
