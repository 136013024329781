import type { SearchCriteria } from '@esp/common/types';

const ACTION_SCOPE = '[Orders Search]';

export namespace CustomerPortalOrdersSearchActions {
  export class Search {
    static readonly type = `${ACTION_SCOPE} Search`;

    constructor(readonly criteria: SearchCriteria) {}
  }

  export class Prune {
    static readonly type = `${ACTION_SCOPE} Prune`;
  }
}
