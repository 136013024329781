import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'customer-portal-layout',
  templateUrl: './layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [RouterModule],
})
export class CustomerPortalLayoutComponent {
  constructor() {
    const platform = inject(Platform);
    const _document = inject(DOCUMENT);
    const renderer = inject(Renderer2);

    if (platform.ANDROID || platform.IOS) {
      renderer.addClass(_document.body, 'is-mobile');
    }
  }
}
